<template>
<div class="page_person">
  <div class="part_item">
    <div class="inner">

      <div class="view_item">
        <div class="view_title">个人信息 <div class="edit_tip" v-show="!personEdit" @click="personEdit=true">编辑</div></div>
        <div class="view_card" v-if="!personEdit">
          <div class="photo">
            <img :src="companyStore.companyAccount.avatarUrl"/>
          </div>
          <div class="person_info">
            <div class="name">{{companyStore.companyAccount.realName}}
              <div v-if="companyStore.companyAccount.certificationStatus === 1" class="authen"><img src="../../assets/img/real_name.svg"/>已实名认证</div>
              <div v-else class="authen" style="rgba(0, 0, 191, 0.76);cursor:pointer;" @click="dialogVisible=true"><img src="../../assets/img/no_real_name.svg"/>请您尽快实名认证</div>
            </div>
            <div class="info">
              <div><span>{{companyStore.companyAccount.postName}}</span></div>
              <div><span>电话：</span>{{ companyStore.companyAccount.mobile }}</div>
              <div><span>微信：</span>{{companyStore.companyAccount.wechat}}</div>
              <div><span>邮箱：</span>{{companyStore.companyAccount.email}}</div>
            </div>
          </div>
        </div>
        <person-card v-else @sub_event="subEvent"></person-card>
      </div>

      <div class="view_item">
        <div class="view_title">密码管理</div>
        <div class="view_card">
          <div class="left_button">
            <el-button round type="primary" @click="resetPassword">重置密码</el-button>
          </div>
          <div class="right_content">
            <el-form>
              <el-form-item label="手机验证码">
                <el-input placeholder="验证码" v-model="msg_code">
                  <template #append>
                    <el-button size="small">
                      <div class="get_msg"  @click="sendMsg" v-show="!showCountdown">获取验证码</div>
                      <div class="get_msg_again" v-show="showCountdown">重新获取({{countDownTotal}}S)</div>
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item label="新密码">
                <el-input v-model="password" placeholder="请输入"  type="password" show-password/>
              </el-form-item>
              <el-form-item label="再次输入新密码">
                <el-input v-model="repeat_password" placeholder="请输入" type="password" show-password/>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>


      <div class="view_item">
        <div class="view_title">我的公司 <div style="display:flex;justify-content: space-between;width:160px;">
          <div class="edit_tip" @click="goUrl('companyDetail')">公司主页</div>
          <div class="edit_tip" @click="goUrl('company')">更换公司</div>
        </div></div>
        <div class="view_card" style="display:flex;align-items: center">
          <img :src="companyStore.companyInfo.logoUrl" style="max-width:310px;margin-right:25px;"/> <span class="normal_black" style="font-size:16px;">{{companyStore.companyInfo.fullName}}</span>
        </div>
      </div>
    </div>
  </div>

  <el-dialog v-model="dialogVisible" title="实名认证" width="40%" center>
    <el-upload
        action=""
        :show-file-list="false"
        :http-request="handleAuthUpload"
    >
      <div class="btn_upload">
        +<br/>上传身份证
      </div>
    </el-upload>
    <template #footer>
      <div class="button_group">
        <el-button @click="dialogVisible = false" round>取消</el-button>
        <el-button type="primary" @click="dialogVisible = false" round>
          保存
        </el-button>
      </div>
    </template>
  </el-dialog>
</div>
</template>

<script>
import { mapStores } from 'pinia';
import { useCompanyStore } from "@/store/company/company";
import personCard from "@/companyPages/components/personCard";
import {authentication,sendResetPasswordMsg,resetPassword} from "@/api/company/company";
export default {
  name: "person",
  data(){
    return {
      dialogVisible:false,
      password:"",
      msg_code:"",
      repeat_password:'',
      personEdit:false,
      showCountdown:false,
      countDownTotal:60
    }
  },
  methods:{
    countDown(){
      this.countDownTotal = this.countDownTotal - 1;
      if(this.countDownTotal<=0){
        this.showCountdown = false;
        return ;
      }
      setTimeout(() => {
        this.countDown();
      },1000)
    },
    sendMsg(){
      // if(this.companyStore.companyAccount.mobile){
      //
      // }
      sendResetPasswordMsg().then(res => {
        if(res.code === 0){
          this.$message({
            type:"success",
            message:"验证码已发送"
          });
          this.showCountdown = true;
        }else{
          this.$message.error(res.msg);
        }
      });
    },
    resetPassword(){
      if(this.msg_code.trim().length < 6){
        this.$message({
          type:"error",
          message:"请输入正确的验证码"
        });
        return;
      }
      if(this.password.trim().length < 6){
        this.$message({
          type:"error",
          message:"密码不低于6位"
        });
        return;
      }
      if(this.password.trim() != this.repeat_password.trim()){
          this.$message({
            type:"error",
            message:"两次密码不一致"
          });
          return;
      }
      resetPassword({
        "captcha": this.msg_code,
        "newPassword": this.password
      }).then(res => {
        if(res.code === 0){
          this.$message({
            type:"success",
            message:"密码已重置"
          });
        }else{
          this.$message({
            type:"error",
            message:res.msg
          });
        }
      });
    },
    handleAuthUpload(file){
      this.loading = true;
      const formData = new FormData();
      formData.append("file", file.file);
      authentication(formData).then(res => {
        this.loading = false;
        if (res.code !== 0) {
          this.$message.error(res.msg);
        }else{
          this.$message({
            type:"success",
            message:"上传成功"
          })

          setTimeout(()=>{
            this.companyStore.getCompanyAccount();
          },300)
        }
      });
    },
    goUrl(act){
      if(act === "company"){
        this.$router.push("/companyInfo");
      }else if(act === "companyDetail"){
        window.location.href = "/#companyDetail?id="+this.companyStore.companyInfo.companyId;
      }
    },
    subEvent(param){
      if(param.act === "person_edit_cancel"){
        this.personEdit = false;
      }
    }
  },
  components:{
    personCard
  },
  watch:{
    showCountdown(v){
      if(v){
        this.countDownTotal = 60;
        setTimeout(()=>{
          this.countDown();
        },1000)
      }
    }
  },
  computed:{
    ...mapStores(useCompanyStore)
  },
}
</script>

<style lang="less">
  .page_person{
    padding-bottom:25px;
    .el-dialog__header{
      text-align: left;
    }
    .el-dialog__body{
      display:flex;
      justify-content: center;
    }
    .el-form-item__content{
      .el-input-group__append{
        box-shadow: none;background-color:#f2f2f2;
      }
      .get_msg{
        color:#505050;
      }
    }
    .button_group{
      text-align: right;
      button{
        height:40px;line-height:40px;
        padding:0;text-align: center;
        width:100px;
      }
    }
    .el-upload{
      margin:0 auto
    }
    .btn_upload{
      width:150px;
      height:150px;
      display:flex;
      background-color:#f3f2f0;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .view_item{
      background-color:#fff;
      padding:30px 40px;
      margin-bottom:10px;
      .view_title{
        display:flex;
        justify-content: space-between;
      }
      .edit_tip{
        font-size: 16px;
        color: #00b0b1;
        cursor:pointer;
      }
      .info{
        display:flex;
        margin-top:10px;
        div{
          margin-right:20px;
          font-size:16px;
          color:#666666;
          span{
            color:#1a1a1a;
          }
        }
      }
      .name{
        display:flex;
        align-items: flex-end;
        font-size: 32px;
        color: #1a1a1a;
        .authen{
          font-size: 14px;
          color: #666666;
          display:flex;
          margin-left:15px;
          margin-bottom:8px;
          img{
            width:24px;height:20px;margin-right:5px;
          }
        }
      }
      .photo{
        margin-right:15px;
        img{
          width:100px;
          height:100px;
          border-radius:100px;
        }
      }
    }
    .view_title{
      font-size: 22px;
      color: #1a1a1a;
      font-weight: bold;
      margin-bottom:15px;
    }
    .view_card{
      display:flex;
    }
    .el-button--primary{
      height:45px;
      width:130px;
      font-size:16px;
    }
    .right_content{
      margin-left:20px;
      .el-form-item__label{
        width:160px;
        font-size: 16px;
        color: #1a1a1a;
      }
      .el-input{
        width:400px;
        height:45px;
      }
    }
  }
</style>
