<template>
  <div class="person_card">
    <div class="photo_edit">
      <el-upload
          class="avatar-uploader"
          action="https://run.mocky.io/v3/9d059bf9-4660-45f2-925d-ce80ad6c4d15"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
      >
        <div class="upload_photo"><img :src="userInfo.avatarUrl"/>上传头像</div>
      </el-upload>
    </div>
    <el-form
        label-position="top"
    >
      <el-form-item label="姓名">
        <el-input v-model="userInfo.realName" placeholder="请输入姓名"/>
      </el-form-item>

      <el-form-item label="电话">
        <el-input placeholder="请输入电话" v-model="userInfo.mobile"/>
      </el-form-item>
      <el-form-item label="职位">
        <el-input placeholder="请输入职位" v-model="userInfo.postName"/>
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input placeholder="请输入邮箱" v-model="userInfo.email"/>
      </el-form-item>
      <el-form-item label="微信">
        <el-input placeholder="微信" v-model="userInfo.wechat"/>
      </el-form-item>
    </el-form>
    <div class="button_group">
      <el-button round style="color:#00b0b1" @click="op('cancel')">取消</el-button>
      <el-button type="primary" round @click="op('save')">保存</el-button>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useCompanyStore } from "@/store/company/company";
import { getPositionList,updateCompanyAccount } from "@/api/company/company";
import { validateEmail } from "@/util/util";

export default {
  name: "personCard",
  data(){
    return {
      userInfo:{
        realName:"",
        wechat:"",
        mobile:"",
        email:""
      },
      positionList:[],
      test:"2014年9月",
    }
  },
  methods:{
    handleAvatarSuccess(){

    },
    beforeAvatarUpload(){

    },
    op(act){
      if(act === "cancel"){
        this.$emit("sub_event",{act:"person_edit_cancel"});
      }else{
        if(!validateEmail(this.userInfo.email)){
          this.$message({
            type:"error",
            message:"请输入正确格式的邮件地址"
          });
          return ;
        }
        updateCompanyAccount({
          realName:this.userInfo.realName,
          wechat:this.userInfo.wechat,
          mobile:this.userInfo.mobile,
          email:this.userInfo.email
        }).then(res => {
          if(res.code === 0){
            this.companyStore.$patch((state) => {
              if(state.companyAccount.certificationStatus === 0){
                state.companyAccount.realName = this.userInfo.realName;
              }
              state.companyAccount.wechat = this.userInfo.wechat;
              state.companyAccount.mobile = this.userInfo.mobile;
              state.companyAccount.email = this.userInfo.email;
            });
          }else{
            this.$message({
              type:"error",
              message:res.msg
            });
          }
          this.op("cancel");
        });
      }
    }
  },
  components:{
  },
  mounted(){
    getPositionList().then(res => {
      console.log(res);
      this.positionList = res.data;
    });
    this.userInfo = JSON.parse(JSON.stringify(this.companyStore.companyAccount));
  },
  computed:{
    ...mapStores(useCompanyStore)
  }
}
</script>

<style lang="less">
@import "../../assets/css/variable";
.person_card{
  padding-top:.1px;
  .el-form{
    display: flex;
    flex-wrap: wrap;
  }
  .el-form-item{
    margin-right:40px;
    width:270px;
    .el-select{
      width:100%;
    }
    //&:nth-child(3n){
    //  margin-right:0;
    //}
  }
  .el-form--label-top .el-form-item__label{
    padding-bottom:0;
  }
}
.upload_photo{
  display:flex;
  align-items: center;
  font-size: 18px;
  color:@blue_color;
  cursor:pointer;
  img{
    width:60px;
    height:60px;
    margin-right:10px;
  }
}
</style>
